<template>
  <PageLoader
    v-if="pageLoading"
    :loading="pageLoading"
    :duration="-1"
    message="uploading"
  ></PageLoader>

  <div v-else class="container_margin">
    <b-modal
      id="export-show-all-pre"
      size="lg"
      centered
      hide-footer
      hide-header
    >
      <h5 class="text-blue"><b>{{ "Precedents" }}</b></h5>

      <div class="card p-2 popUp_card">
        <ol>
          <li class="font-14 my-1"
            v-for="(pre, index) in precedents"
            :key="'precedents_all' + index"
          >{{ pre }}</li>
        </ol>
      </div>
    </b-modal>

    <div class="row">
      <div class="col-12 col-md-6 col-xl-9 mt-3">
        <div class="card border shadow-sm p-3 rounded h-100">
          <div class="row">
            <div class="col">
              <h5 class="text-blue">
                <b>{{ "Precedents" }}</b>
              </h5>
            </div>

            <div class="col" v-if="precedents.length > 10">
              <span v-b-modal.export-show-all-pre class="text-blue float-right">
                Show All
              </span>
            </div>
          </div>
          
          <div
            v-if="precedents.length == 0"
            class="w-100 d-flex flex-column"
          >
            <img
              style="width: 200px; height: auto; margin: auto; margin-bottom: 1rem;"
              src="@/assets/noData.png"
              alt="No Data Image"
            />
            <p class="text-center text-blue font-weight-bold mb-0">
              There is no precedents for this case
            </p>
          </div>

          <div v-else class="row h-100">
            <div
              class="col-sm-12 col-xl-6"
              v-for="(pre, index) in precedents.slice(0, 10)"
              :key="'precedents_' + index"
            >
              <p class="font-14">{{ index + 1 }}. {{ pre }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="col mt-3">
        <div class="card border shadow-sm p-3 rounded h-100 minH">
          <h5 class="text-blue"><b>{{ "Export Summary To" }}</b></h5>

          <div class="row">
            <div
              class="col-6 col-md-12 mt-1"
              v-for="(export_item, export_item_normal) in exports_items"
              :key="'export_pdf' + export_item_normal"
            >
              <div class="d-flex align-items-center mt-1">
                <input
                  :name="'pdf' + export_item_normal"
                  :id="'pdf' + export_item_normal"
                  type="checkbox"
                  :value="export_item"
                  v-model="pdf_status"
                  class="form-check-input mb-2"
                />

                <span class="material-icons-outlined icon-export-blue mx-2">
                  {{ export_item.icon }}
                </span>

                <b class="text-blue">{{ export_item.name }}</b>
              </div>
            </div>
          </div>

          <div class="form-check form-switch mt-3">
            <input
              class="form-check-input float-right"
              type="checkbox"
              role="switch"
              v-model="status"
            />
            <label
              class="form-check-label small text-blue"
              v-b-tooltip.hover.bottom="{ variant: 'secondary' }"
              title="Click To Export with NER Tags"
            >
              {{ "Export with NER Tags" }}
            </label>
          </div>

          <div
            class="mt-4 mt-md-0"
            style="position: absolute; bottom: 1rem; right: 1rem"
          >
            <button @click="exportData" class="btn btn-primary float-right">
              {{ "Export" }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div id="nerAccordion" class="accordion mt-3">
      <div class="card border shadow-sm py-1 px-3 rounded">
        <div
          id="nerAccordionHeading"
          class="card-header cursor-pointer"
          data-toggle="collapse"
          data-target="#nerAccordionContent"
          aria-controls="nerAccordionContent"
          aria-expanded="true"
        >
          <label class="accordion-label text-blue font-weight-bold" for="toggleFilters">
            All NER Entities [Summary]
            <span class="material-symbols-outlined float-right accordion-icon">
              expand_less
            </span>
          </label>
          <p>{{ "*NER = Natural Language Processing Technique that identifies and classifies named entities from the case" }}</p>
        </div>

        <div
          id="nerAccordionContent"
          class="collapse"
          aria-labelledby="nerAccordionHeading"
          data-parent="#nerAccordion"
        >
          <div class="card border-blue shadow-sm p-2 rounded table-card mb-3">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col" width="25%">{{ "Abbreviations" }}</th>
                  <th scope="col" width="20%">{{ "Full Form" }}</th>
                  <th scope="col" width="50%">{{ "Entities" }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in ner_final"
                  :key="'ner_list' + index"
                  :class="item.Color"
                >
                  <td width="25%">{{ item.abbreviation }}</td>
                  <td width="20%">{{ item.full_form }}</td>
                  <td width="50%">
                    <p
                      v-for="(ent, index) in item.Entities"
                      :key="'Entities' + index"
                    >
                      {{ ent }}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-6 mt-4">
        <h4 class="text-center text-blue"><b>{{ "Complete Case" }}</b></h4>

        <div class="card shadow-sm p-4 mt-2 card_bot_question">
          <iframe :src="case_file" height="703px"></iframe>
        </div>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-4">
        <h4 class="text-blue text-center">
          <b>{{ "Case Summary & LawBot" }}</b>
        </h4>

        <div class="card shadow-sm p-3 rounded mt-2">
          <div class="d-flex mb-3 justify-content-between">
            <div class="tabs__folderTabs">
              <button
                @click="selectedTab = 'caseDocument'"
                style="margin-right: .5rem;"
                :class="{
                  folderTab: selectedTab === 'caseDocument',
                  active: selectedTab === 'caseDocument',
                }"
              >
                <span class="folderTab__title">{{ "Case Summary" }}</span>
              </button>
              <button
                v-if="permissions.includes('nexlaw.qbot')"
                @click="selectedTab = 'qBot'"
                :class="{
                  folderTab: selectedTab === 'qBot',
                  active: selectedTab === 'qBot',
                }"
              >
                <span class="folderTab__title">
                  {{ "LawBot" }}
                  <b-icon
                    icon="stars"
                    class="mx-1"
                    font-scale="1.3"
                    :animation="selectedTab !== 'qBot' ? 'fade' : 'none'"
                  ></b-icon>
                </span>
              </button>
            </div>

            <div
              v-if="selectedTab === 'caseDocument'"
              class="d-flex align-items-center"
            >
              <span
                v-b-tooltip.hover.top="{ variant: 'secondary' }"
                title="Copy"
                @click="copyContent"
                class="cursor-pointer material-icons-outlined icon-blue mx-2"
              >
                content_copy
              </span>
            </div>
          </div>

          <div
            v-if="selectedTab === 'caseDocument'"
            class="row summary_card"
          >
            <div class="col">
              <div class="my-3">
                <b class="text-blue">{{ "Brief Summary" }}</b>
                <span
                  v-b-tooltip.hover.top="{ variant: 'secondary' }"
                  :title="isSpeaking ? 'Pause' : 'Audio Summary'"
                  @click="toggleSpeech(content_original)"
                  class="float-right cursor-pointer material-symbols-outlined icon-blue"
                >
                  {{ isSpeaking ? "pause" : "text_to_speech" }}
                </span>
              </div>

              <p
                v-if="checked"
                id="ner_content"
                class="ner_content"
                v-html="content"
              ></p>
              <p v-else class="ner_content">{{ content_original }}</p>

              <b class="text-blue mt-4">{{ "Key Points" }}</b>
              <div
                class="row border-bottom m-2"
                v-for="(key_point, index) in key_points_original"
                :key="'key_points' + index"
              >
                <div class="col-1">
                  <p class="ner_content font-weight-bold float-left">
                    {{ index + 1 }}
                  </p>
                </div>
                <div class="col">
                  <p
                    v-if="checked"
                    class="ner_content"
                    id="ner_content"
                    v-html="key_points[index]"
                  ></p>
                  <p v-else class="ner_content">{{ key_point }}</p>
                </div>
                <div class="col-1">
                  <span
                    v-b-tooltip.hover.left="{ variant: 'secondary' }"
                    :title="
                      buttonStates[index].isSpeaking ? 'Pause' : 'Audio'
                    "
                    @click="toggleSpeech(key_point, index)"
                    class="float-right cursor-pointer material-symbols-outlined icon-blue mt-1"
                  >
                    {{
                      buttonStates[index].isSpeaking
                        ? "pause"
                        : "text_to_speech"
                    }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="selectedTab === 'qBot'"
            class="card border-0 card_question"
          >
            <div class="chat__box" id="messages" ref="messages1">
              <div
                v-for="(message, idx) in chatMessages"
                :key="idx"
                :class="message.class_1"
              >
                <div :class="message.class_1">
                  <div :class="message.class_3">
                    <span
                      v-html="message.text
                        .replace(/\\n*/g, '<br>')
                        .replace(/\\n\\n*/g, '<br>')
                        .replace(/\\n\\n/g, '<br>')
                        .replace(/\\n/g, '<br>')
                        .replace(/\n/g, '<br>')
                        .replace(/\n\n*/g, '<br>')
                        .replace('*', '')
                        .replace(/\\/g, '<br>')
                    "></span>
                  </div>
                </div>
              </div>

              <div v-if="loadAnswer" class="chat__incoming">
                <div class="chat__bubble__wrapper-incoming">
                  <div class="chat__bubble-incoming animated-background-incoming">
                    <div
                      style="transform: scale(0.7)"
                      class="circles-to-rhombuses-spinner"
                    >
                      <div class="circle"></div>
                      <div class="circle"></div>
                      <div class="circle"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <button
              class="chat__suggestion"
              v-for="(question, index) in default_questions.slice(0, 3)"
              :key="index"
              @click="chooseMessage(question, index)"
              :disabled="loadAnswer || question.displayed"
            >
              <span>{{ question.text }}</span>
            </button>

            <div class="chat__input">
              <input
                :disabled="loadAnswer"
                @keyup.enter="sendNewMessage"
                type="text"
                v-model="NewText"
                placeholder="Ask Questions about the Case..."
              />
              <button
                v-show="NewText.length > 0"
                @click="sendNewMessage"
                :disabled="loadAnswer"
                class="btn-send"
              >
                <span class="material-icons-sharp">arrow_circle_right</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import PageLoader from "../../components/PageLoader.vue";
import LegalWriter from "@/store/LegalWriter.js";
import Auth from "@/store/Auth.js";
import { ExportData } from "../../store/utils";

export default {
  components: {
    PageLoader,
  },

  data() {
    return {
      permissions: localStorage.permissions,
      NewText: "",
      loadAnswer: false,
      selectedTab: "caseDocument",
      chatMessages: [],
      default_questions: [
        { text: "What is the ratio decidendi of the case?", displayed: false },
        {
          text: "What are the relevant obiter dicta of the case?",
          displayed: false,
        },
        {
          text: "What is the key legal principle or rule that the court relied on in reaching its decision?",
          displayed: false,
        },
        {
          text: "Can you identify the specific reasoning provided by the court that led to the judgment in this case?",
          displayed: false,
        },
        {
          text: "How does the court's decision in this case impact future cases and legal interpretations?",
          displayed: false,
        },
        {
          text: "What factors influenced the court's interpretation of the law and application to the facts of this case?",
          displayed: false,
        },
        {
          text: "Are there any dissenting opinions or conflicting views among the judges that need to be considered?",
          displayed: false,
        },
        {
          text: "What implications does this judgment have for similar cases or legal issues in the future?",
          displayed: false,
        },
      ],
      pageLoading: true,
      case_file: "",
      content_to_copy: "",
      content_to_export: "",
      content_to_export_without_ner: "",
      precedents: [],
      content: "",
      exports_items: [
        { name: "PDF", icon: "picture_as_pdf" },
        { name: "DOCUMENT", icon: "description" },
      ],
      status: false, // Checkbox status for "Export with NER Tags"
      pdf_status: [],
      checked: false,
      ner_list: [],
      entities: [],
      chosenPage: "",
      pages: [],
      languages: [
        "English",
        "Bahasa Malaysia",
        "Filiphino",
        "Thai",
        "Tamil",
        "Chinese",
      ],
      chosenLanguage: "",
      key_points: [],
      key_points_original: [],
      isSpeaking: false,
      utterance: null,
      buttonStates: [],
      colors: {
        LAWYER: "#7EDFFF",
        COURT: "#9ACD32",
        JUDGE: "#C6BFFF",
        PETITIONER: "#FF7E7E",
        RESPONDENT: "#FFA500",
        CASE_NUMBER: "#BCC2D8",
        GPE: "#87CEFA",
        DATE: "#C1FFC1",
        ORG: "#FFDD7F",
        STATUTE: "#F5DEB3",
        WITNESS: "#FFFF8F",
        PRECEDENT: "#CBE3E2",
        PROVISION: "#F4A460",
        OTHER_PERSON: "#CBCBCB",
      },
      full_form: {
        LAWYER: "lawyer",
        COURT: "court",
        JUDGE: "judge",
        PETITIONER: "petitioner",
        RESPONDENT: "respondent",
        CASE_NUMBER: "Case number",
        GPE: "Countries, cities, state",
        DATE: "date",
        ORG: "organisation",
        STATUTE: "statute",
        WITNESS: "witness",
        PRECEDENT: "precedent",
        PROVISION: "provision",
        OTHER_PERSON: "People names",
      },
      content_original: "",
      ner_final: [],
    };
  },

  computed: {
    filteredNerFinal() {
      return this.ner_final.filter((item) => item.abbreviation !== "PRECEDENT");
    },
  },

  methods: {
    chooseMessage(message, index) {
      this.loadAnswer = true;
      this.chatMessages.push({
        text: message.text,
        class_1: "chat__outgoing",
        class_2: "chat__bubble__wrapper-outgoing",
        class_3: "chat__bubble-outgoing animated-background-outgoing",
        new: false,
      });

      // Update the displayed property of the chosen question
      this.default_questions[index].displayed = true;

      var obj = {
        question: message.text,
        mask: "true",
      };

      console.log("Passed into API", obj);
      LegalWriter.sendQuestion("document", this.$route.params.case_id, obj)
        .then(() => {
          this.default_questions.splice(
            this.default_questions.findIndex((x) => x.text === message.text),
            1
          );
          this.calltime("default");
        })
        .catch((error) => {
          this.loadAnswer = false;
          console.log(error);
        });

      var div = document.getElementById("messages");
      div.scrollTop = div.scrollHeight;

      this.$nextTick(() => {
        var messageDisplay = this.$refs.messages1;
        messageDisplay.scrollTop = messageDisplay.scrollHeight;
      });
    },

    calltime(data) {
      var me = this;
      setTimeout(function () {
        if (!me.$route.params.id) return;
        
        LegalWriter.openDocument(me.$route.params.id, me.$route.params.case_id)
          .then((response) => {
            const questions = response.data.data.questions;
            const last_q = questions[questions.length - 1];
            if (last_q.answer != null) {
              try {
                JSON.parse(last_q.answer).forEach((answer) => {
                  me.chatMessages.push({
                    text: answer,
                    class_1: "chat__incoming",
                    class_2: "chat__bubble__wrapper-incoming",
                    class_3:
                      "chat__bubble-incoming animated-background-incoming",
                    new: true,
                  });
                });
              } catch (error) {
                me.chatMessages.push({
                  text: last_q.answer,
                  class_1: "chat__incoming",
                  class_2: "chat__bubble__wrapper-incoming",
                  class_3: "chat__bubble-incoming animated-background-incoming",
                  new: true,
                });
              }
              me.loadAnswer = false;

              if (data == "default") {
                me.default_questions.splice(index, 1);
              }
            } else {
              me.calltime(data);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }, 5000);
    },

    sendNewMessage() {
      if (this.NewText.length == 0) return;

      this.loadAnswer = true;
      this.chatMessages.push({
        text: this.NewText,
        class_1: "chat__outgoing",
        class_2: "chat__bubble__wrapper-outgoing",
        class_3: "chat__bubble-outgoing animated-background-outgoing",
        new: false,
      });

      var obj = {
        question: this.NewText,
        mask: "true",
      };
      this.NewText = "";

      console.log("Passed into API", obj);
      LegalWriter.sendQuestion("document", this.$route.params.case_id, obj)
        .then(() => {
          Auth.getMe().then((res) => {
            localStorage.setItem("userInfo", JSON.stringify(res.data.data));
            const credits = res.data.data.nexlaw_credits;
            this.contract_questions_full = credits.full.contract_questions;
            this.contract_questions_remain =
              credits.full.contract_questions - credits.used.contract_questions;
          });
          this.calltime("none");
        })
        .catch((error) => {
          console.log(error);
          this.loadAnswer = false;
          this.loadAnswer = false;
          console.log(error);
          this.loadAnswer = false;
          console.log(error);
        });

      var div = document.getElementById("messages");
      div.scrollTop = div.scrollHeight;

      this.$nextTick(() => {
        var messageDisplay = this.$refs.messages1;
        messageDisplay.scrollTop = messageDisplay.scrollHeight;
      });
    },

    scrollToBottom() {
      // Scroll to the bottom of the chat container
      this.$nextTick(() => {
        const chatContainer = this.$refs.messages1;
        chatContainer.scrollTop = chatContainer.scrollHeight;
      });
    },

    initializeUtterance(speechText, index = null) {
      const target = index == null ? this : this.buttonStates[index];

      this.utterance = new SpeechSynthesisUtterance(speechText);
      this.utterance.onstart = () => {
        target.isSpeaking = true;
      };
      this.utterance.onend = () => {
        speechSynthesis.cancel();
        target.isSpeaking = false;
      };
    },

    toggleSpeech(text, index = null) {
      const target = index == null ? this : this.buttonStates[index];
      // console.log(text);
      // console.log(target.isSpeaking);

      if (!target.isSpeaking) {
        // console.log("Utterance:", this.utterance);
        if (this.utterance?.text === text) {
          try {
            speechSynthesis.resume();
            target.isSpeaking = true;
          } catch (err) {
            console.error("Failed to resume:", err);
            this.initializeUtterance(speechText, index);
            speechSynthesis.speak(this.utterance);
          }
        } else {
          speechSynthesis.cancel();
          target.isSpeaking = false;

          this.initializeUtterance(text, index);
          speechSynthesis.speak(this.utterance);
        }
      } else {
        speechSynthesis.pause();
        target.isSpeaking = false;
        this.utterance.onpause = null; // Remove the event listener after handling
      }
    },

    exportData() {
      if (this.pdf_status.length === 0) {
        this.$toast.error("Kindly select a format to export");
        return;
      }

      var status = ExportData(
        this.status
          ? this.content_to_export
          : this.content_to_export_without_ner,
        [
          this.pdf_status.find((element) => element.name === "DOCUMENT"),
          this.pdf_status.find((element) => element.name === "PDF"),
        ]
      );

      if (status[0]) {
        this.$toast.success("Successfully downloaded Document");
      }
      if (status[1]) {
        this.$toast.success("Successfully downloaded PDF");
      }

      this.pdf_status = [];
    },

    copyContent() {
      navigator.clipboard.writeText(this.content_to_copy);
      this.$toast.success("Copied");
    },
  },

  created() {
    LegalWriter.openDocument(this.$route.params.id, this.$route.params.case_id)
      .then((response) => {
        function escapeRegExp(string) {
          return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
        }

        this.pageLoading = false;
        const DATA = response.data.data;

        this.case_file = DATA.case_file;

        this.precedents = JSON.parse(
          DATA.values.find((el) => el.key === "precedents").value
        );

        for (const [key, value] of Object.entries(
          JSON.parse(DATA.values.find((el) => el.key === "ner").value)
        )) {
          this.ner_list.push({
            Entities: key,
            abbreviation: value,
          });
        }

        this.content_original = JSON.parse(
          DATA.values.find((el) => el.key === "brief_summary").value
        );
        this.content = this.content_original;

        this.key_points_original = JSON.parse(
          DATA.values.find((el) => el.key === "key_points").value
        );

        this.key_points = this.key_points_original.map((element) => {
          return this.ner_list.reduce((acc, element_ner) => {
            const escapedEntities = escapeRegExp(element_ner.Entities);
            const regex = new RegExp(escapedEntities, "g");
            return acc.replace(
              regex,
              `<span class="ner_badge ${element_ner.abbreviation}">${element_ner.Entities}</span>`
            );
          }, element);
        });

        this.buttonStates = this.key_points_original.map(() => ({
          isSpeaking: false,
        }));

        this.ner_list.forEach(({ Entities, abbreviation }) => {
          this.content = this.content.replaceAll(
            new RegExp(escapeRegExp(Entities), "g"),
            `<span class="ner_badge ${abbreviation}"> ${Entities} </span>`
          );
        });

        const categorizedData = this.ner_list.reduce((result, item) => {
          // Check if the category exists in the result
          if (!result[item.abbreviation]) {
            result[item.abbreviation] = [];
          }
          // Push the item into the corresponding category
          result[item.abbreviation].push(item.Entities);
          return result;
        }, {});
        this.ner_final = Object.entries(categorizedData).map(
          ([abbreviation, Entities]) => {
            const full_form = this.full_form[abbreviation];
            return {
              Color: abbreviation,
              abbreviation,
              full_form,
              Entities,
            };
          }
        );

        this.content_to_copy = `Brief Summary \n\n${this.content_original}\n\nKey Points`;
        this.content_to_copy = this.key_points_original
          .map((element, index) => {
            return `${index + 1}) ${element}`;
          })
          .join("\n\n");

        this.content_to_export_without_ner = `<b>Brief Summary</b><br>${this.content_original}<br><br><b>Key Points</b>`;
        this.content_to_export_without_ner += this.key_points_original
          .map((element, index) => {
            return `${index + 1}) ${element}`;
          })
          .join("<br>");
        this.content_to_export_without_ner += `<br><br><b>Precedents</b>`;
        this.content_to_export_without_ner += this.precedents.join("<br>");

        this.content_to_export = this.content_to_export_without_ner;
        this.content_to_export += `<br><br><b>NER</b>`;
        this.content_to_export += this.ner_list
          .map((element) => `${element.Entities} : ${element.abbreviation}`)
          .join("<br>");

        DATA.questions.forEach((element) => {
          if (element.answer.length != 0) {
            this.default_questions.splice(
              this.default_questions[
                this.default_questions.findIndex((x) => x === element.question)
              ],
              1
            );

            this.chatMessages.push({
              text: element.question,
              class_1: "chat__outgoing",
              class_2: "chat__bubble__wrapper-outgoing",
              class_3: "chat__bubble-outgoing animated-background-outgoing",
              new: false,
            });

            try {
              JSON.parse(element.answer).forEach((answer) => {
                this.chatMessages.push({
                  text: answer,
                  class_1: "chat__incoming",
                  class_2: "chat__bubble__wrapper-incoming",
                  class_3: "chat__bubble-incoming animated-background-incoming",
                  new: false,
                });
              });
            } catch (error) {
              this.chatMessages.push({
                text: element.answer,
                class_1: "chat__incoming",
                class_2: "chat__bubble__wrapper-incoming",
                class_3: "chat__bubble-incoming animated-background-incoming",
                new: false,
              });
            }
          }
        });

        if (
          DATA.questions.length != 0 &&
          DATA.questions[DATA.questions.length - 1].answer == null
        ) {
          this.loadAnswer = true;
          this.calltime("none");

          this.chatMessages.push({
            text: DATA.questions[DATA.questions.length - 1].question,
            class_1: "chat__outgoing",
            class_2: "chat__bubble__wrapper-outgoing",
            class_3: "chat__bubble-outgoing animated-background-outgoing",
            new: false,
          });
        }

        if (this.chatMessages.length == 0) {
          this.chatMessages.push({
            text: "You may ask question about the case here🔎",
            class_1: "chat__incoming",
            class_2: "chat__bubble__wrapper-incoming",
            class_3: "chat__bubble-incoming animated-background-incoming",
            new: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style scoped>
.container_margin {
  padding: 1rem 2rem;
}

.icon-export-blue {
  color: #094890;
  font-size: 2rem;
}

.ner_badge {
  border-radius: 10px;
  border: none;
  color: white;
  padding: 0.5rem;
}

.ner_content ::v-deep .ner_badge {
  border-radius: 10px;
  border: none;
  padding: 0.5rem;
}
.badge_color {
  background: #fda355;
}
.ner_content ::v-deep .badge_color {
  background: #fda355;
}

.ner_content {
  line-height: 2rem;
}

.popover {
  max-width: 500px !important;
}

.ner_content ::v-deep .LAWYER,
.table .LAWYER {
  background: #7edfff;
}

.ner_content ::v-deep .COURT,
.table .COURT {
  background: #9acd32;
}

.ner_content ::v-deep .JUDGE,
.table .JUDGE {
  background: #c6bfff;
}

.ner_content ::v-deep .PETITIONER,
.table .PETITIONER {
  background: #ff7e7e;
}

.ner_content ::v-deep .RESPONDENT,
.table .RESPONDENT {
  background: #ffa500;
}

.ner_content ::v-deep .CASE_NUMBER,
.table .CASE_NUMBER {
  background: #bcc2d8;
}

.ner_content ::v-deep .GPE,
.table .GPE {
  background: #87cefa;
}

.ner_content ::v-deep .DATE,
.table .DATE {
  background: #c1ffc1;
}

.ner_content ::v-deep .ORG,
.table .ORG {
  background: #ffdd7f;
}

.ner_content ::v-deep .STATUTE,
.table .STATUTE {
  background: #f5deb3;
}

.ner_content ::v-deep .WITNESS,
.table .WITNESS {
  background: #ffff8f;
}

.ner_content ::v-deep .PRECEDENT,
.table .PRECEDENT {
  background: #cbe3e2;
}

.ner_content ::v-deep .PROVISION,
.table .PROVISION {
  background: #f4a460;
}

.ner_content ::v-deep .OTHER_PERSON,
.table .OTHER_PERSON {
  background: #cbcbcb;
}

.table {
  --bs-table-bg: none;
}

.popUp_card {
  max-height: 600px !important;
  overflow-x: hidden;
  overflow-y: scroll;
}

.summary_card {
  max-height: 675px !important;
  overflow-x: hidden;
  overflow-y: scroll;
}

.table {
  border: none;
  background: white;
  table-layout: fixed;
  font-size: 14px;
}

tbody {
  display: block;
  max-height: 300px;
  overflow-y: scroll;
}

thead {
  display: table;
  width: 100%;
  table-layout: fixed;
  overflow-x: auto;
  border-bottom: 0.1vw solid #ddd !important;
  background: white;
}

tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
  overflow-x: auto;
  border: none;
  background: white;
}

td {
  border-bottom: 0.001px solid #ddd;
  border-top: none;
  vertical-align: top;
}

th {
  border: none;
  color: #094890;
}

.form-check-input:checked {
  border-color: #094890;
  background-color: #094890;
}

.form-switch .form-check-input {
  box-shadow: #094890;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23094890'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:focus:checked {
  box-shadow: #094890;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  box-shadow: #094890;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:focus {
  box-shadow: #094890;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23094890'/%3e%3c/svg%3e");
}

/* chatBot Css */
.animated-background-outgoing {
  background: linear-gradient(
    45deg,
    #012741,
    #004877,
    #006bb3,
    #0866a5,
    #0976be
  );
  background-size: 200% 100%;
  animation: gradientAnimation 3s linear infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 0%;
  }

  25% {
    background-position: 100% 0%;
  }

  50% {
    background-position: 100% 100%;
  }

  75% {
    background-position: 0% 100%;
  }

  100% {
    background-position: 0% 0%;
  }
}

.animated-background-incoming {
  background: linear-gradient(
    45deg,
    #cccaca,
    #bcbebe,
    #d4cece,
    #d3d4d4,
    #f1f1f1
  );
  background-size: 200% 100%;
  animation: gradientAnimation 3s linear infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 0%;
  }

  25% {
    background-position: 100% 0%;
  }

  50% {
    background-position: 100% 100%;
  }

  75% {
    background-position: 0% 100%;
  }

  100% {
    background-position: 0% 0%;
  }
}

.card_bot_question {
  height: 762px;
  overflow-y: scroll;
}

.card_question {
  height: 674px;
}

.chat__box {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  height: 100%;
  overflow-y: scroll;
}

.chat__incoming {
  margin-right: auto;
  margin-top: 0.5rem;
}

.chat__bubble__wrapper-incoming {
  margin-right: auto;
  margin-bottom: 1rem;
}

.chat__bubble-incoming {
  padding: 0.3rem 1rem;
  background-color: #f1f1f1;
  border-radius: 30px;
  border-bottom-left-radius: 0px;
  margin-right: 3rem;
  margin-left: 0.5rem;
}

.chat__bubble-incoming span {
  color: #333333;
}

.chat__outgoing {
  margin-left: auto;
  margin-top: 0.5rem;
}

.chat__bubble__wrapper-outgoing {
  margin-left: auto;
  margin-bottom: 1rem;
}

.chat__bubble-outgoing {
  padding: 0.3rem 1rem;
  background-color: #86d4f8;
  border-radius: 30px;
  border-bottom-right-radius: 0px;
  margin-left: 3rem;
}

.chat__bubble-outgoing span {
  color: #fff;
}

/* chat suggestions and input */
.chat__suggestion {
  display: flex;
  border: 2px solid #dddddd;
  border-radius: 20px;
  padding-left: 10px;
  height: auto;
  margin-bottom: 0.5rem;
}

.chat__suggestion:hover {
  background-color: #f1f1f1;
  transition: 300ms ease-out;
}

.chat__suggestion span {
  text-align: left;
  padding-top: 0.2rem;
}

.chat__input {
  display: flex;
  border: 2px solid #0058a2;
  border-radius: 20px;
  padding-left: 10px;
}

.chat__input input {
  border: none;
  border-radius: 20px;
  height: 40px;
  width: 100%;
  box-shadow: none;
  outline: none;
}

.chat__input .btn-send {
  padding: 0;
  margin: 0;
  height: 38px;
}

.chat__input .btn-send span {
  color: #0058a2;
  font-size: 40px;
  transition: 300ms ease-out;
}

.chat__input .btn-send:hover span {
  color: #004278;
}

.accordion-checkbox {
  display: none;
}

.accordion-label {
  cursor: pointer;
  display: block;
}

.accordion-icon {
  transition: transform 0.3s;
}

.accordion-checkbox:checked ~ .accordion-label .accordion-icon {
  transform: rotate(-180deg);
}

.card-header {
  padding: 0rem 0rem !important;
  padding-top: 1rem !important;
  margin-bottom: 0 !important;
  color: var(--bs-card-cap-color) !important;
  background-color: #ffffff !important;
  border-bottom: 0px solid #ffffff !important;
}

@media screen and (max-width: 767.98px) {
  .minH {
    min-height: 275px;
  }
}
</style>
